import {throwError as observableThrowError, Observable, Subject} from "rxjs";
import {map, catchError} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {GeneralServices} from "../general-services";
import {environment} from "../../../environments/environment";

import {
  HttpClient,
  HttpHeaders,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent
} from "@angular/common/http";
import "rxjs/add/operator/do";

import {Router} from "@angular/router";
import posthog from 'posthog-js';
@Injectable()
export class ServerAuthService {
  logInHeaders = new HttpHeaders();
  commonHeader = new HttpHeaders();
  private baseApiUrl;
  private baseApiUrlCampaign;
  private baseApiUrlAutomation;
  private clientId;
  private getLogInHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({Accept: "application/x.gymday.v1+json"})
    };
    return httpOptions;
  }

  private getLogOutHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/x.gymday.v1+json",
        Authorization: "Bearer " + this.getToken()
      })
    };
    return httpOptions;
  }

  private getCommonHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/x.gymday.v1+json",
        Authorization: "Bearer " + this.getToken()
      })
    };
    return httpOptions;
  }

  private getCampaignHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getToken()
      })
    };
    return httpOptions;
  }

  private getAutomationsHeader() {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
         Authorization: "Bearer " + this.getToken()
      })
    };
    return httpOptions;
  }

  private searchClientHeader() {
    const httpOptions = {
      headers: new HttpHeaders({"Content-Type": "application/json", Accept: "application/x.gymday.v1+json"})
    };
    return httpOptions;
  }

  private getBlobHeader() {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/csv; charset=UTF-8",
        Accept: "application/csv",
        Authorization: "Bearer " + this.getToken()
      })
    };
    return httpOptions;
  }

  private getFormdataHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/x.gymday.v1+json",
        Authorization: "Bearer " + this.getToken()
      })
    };
    return httpOptions;
  }

  private getToken() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if(currentUser) {
      const token = JSON.parse(localStorage.getItem("currentUser"))['token'];
      return token;
    }
  }

  // private getClient() {
  //   var clientId = JSON.parse(localStorage.getItem("currentUser")).client_id;
  //   return "?client_id=" + clientId;
  // }

  constructor(private http : HttpClient, private router : Router, private generalFunctions : GeneralServices) {
    this.baseApiUrl = environment.baseUrl + "api/";
    this.baseApiUrlCampaign = environment.baseUrlCampaign;
    this.baseApiUrlAutomation = environment.baseApiUrlAutomation;
    // this.clientId = JSON.parse(localStorage.getItem("currentUser")).client_id;
  }

  //GET LOCATION
  getLocation(pin): Observable<any> {
    return this.http.get(this.baseApiUrl + "location/search/" + pin, this.getLogInHeaders()).pipe(map((response : Response) => {
      return response;
    }), catchError(e => {
      if (e.status === 422) {
        e = e;
        var err: any = {};
        if (e["errors"]) {
          err.message = e["errors"][Object.keys(e["errors"])[0]];
        } else {
          err.message = e.message;
        }
        return observableThrowError(err);
      }
      return observableThrowError(e);
    }));
  }

  //SIGNUP FUNCTION
  signup(data): Observable<any> {
    return this.http.post(this.baseApiUrl + "register", data, this.getLogInHeaders()).pipe(map((response : Response) => {
      return response;
    }), catchError(e => {
      if (e.status === 422) {
        e = e;
        var err: any = {};
        if (e["errors"]) {
          err.message = e["errors"][Object.keys(e["errors"])[0]];
        } else {
          err.message = e.message;
        }
        return observableThrowError(err);
      }
      return observableThrowError(e);
    }));
  }

  //SEND EMAIL VERIFICATION LINK
  resendEmailVerification(data): Observable<any> {
    return this.http.post(this.baseApiUrl + "email/resend", data).pipe(map((response : Response) => {
      return response;
    }), catchError(e => {
      if (e.status === 422) {
        e = e;
        var err: any = {};
        if (e["errors"]) {
          err.message = e["errors"][Object.keys(e["errors"])[0]];
        } else {
          err.message = e.message;
        }
        return observableThrowError(err);
      }
      return observableThrowError(e);
    }));
  }

  //VERIFY EMAIL
  verifyEmail(id): Observable<any> {
    return this.http.post(this.baseApiUrl + "email/verify/" + id, {}, this.getLogInHeaders()).pipe(map((response : Response) => {
      return response;
    }), catchError(e => {
      if (e.status === 422) {
        // var err: any = {};
        // if (e["errors"]) {
        // 	err.message = e["errors"][Object.keys(e["errors"])[0]];
        // } else {
        // 	err.message = e.message;
        // }
        return observableThrowError(e);
      }
      return observableThrowError(e);
    }));
  }

  //VERIFY EMAIL
  setPassword(id, obj): Observable<any> {
    return this.http.post(this.baseApiUrl + "email/verify/" + id, obj, this.getLogInHeaders()).pipe(map((response : Response) => {
      return response;
    }), catchError(e => {
      if (e.status === 422) {
        e = e;
        var err: any = {};
        if (e["errors"]) {
          err.message = e["errors"][Object.keys(e["errors"])[0]];
        } else {
          err.message = e.message;
        }
        return observableThrowError(err);
      }
      return observableThrowError(e);
    }));
  }

  //LOGIN FUNCTION
  login(email : string, password : string, client_id : any): Observable<any> {
    return this.http.post(this.baseApiUrl + "login", {
      email: email,
      password: password,
      client_id: client_id
    }, this.getLogInHeaders()).pipe(map((response : Response) => {
      return response;
    }), catchError(e => {
      return this.catchErrors(e);
    }));
  }

  //RESET PASSWORD LINK
  resetPasswordLink(data): Observable<any> {
    return this.http.post(this.baseApiUrl + "password/forgot", data, this.getLogInHeaders()).pipe(map((response : Response) => {
      return response;
    }), catchError(e => {
      e = e;
      var err = {
        message: null
      };
      if (e["errors"]) {
        err.message = e["errors"][Object.keys(e["errors"])[0]];
      } else {
        err.message = e.message;
      }
      return observableThrowError(err);
    }));
  }

  //RESET CLIENT PASSWORD
  resetPassword(data): Observable<any> {
    return this.http.post(this.baseApiUrl + "password/reset", data, this.getLogInHeaders()).pipe(map((response : Response) => {
      return response;
    }), catchError(e => {
      e = e;
      var err = {
        message: null
      };
      if (e["error"]["errors"]) {
          console.log('w',e.error["errors"][Object.keys(e.error["errors"])[0]] )
        err.message = e.error["errors"][Object.keys(e.error["errors"])[0]];
      } else {
        err.message = e.message;
      }
      console.log('err', err)
      return observableThrowError(err);
    }));
  }

  linkValidation(data): Observable<any> {
    return this.http.post(this.baseApiUrl + "password/reset/token/verify", data, this.getLogInHeaders())
    .pipe(map((response: Response) => {
        return response;
      }), catchError(e => {
        return this.catchErrors(e);
      }));
  }

  //GET FORM LINK DATA
  getLinkData(id): Observable<any> {
    return this.http.get(this.baseApiUrl + "contact/formlink/" + id, this.getLogInHeaders()).pipe(map((response : Response) => {
      return response;
    }), catchError(e => {
      if (e.status === 422) {
        e = e;
        var err: any = {};
        if (e["errors"]) {
          err.message = e["errors"][Object.keys(e["errors"])[0]];
        } else {
          err.message = e.message;
        }
        return observableThrowError(err);
      }
      return observableThrowError(e);
    }));
  }

  //SEND FORM LINK DATA
  sendLinkData(data): Observable<any> {
    return this.http.post(this.baseApiUrl + "contact/formlink", data, this.getLogInHeaders()).pipe(map((response : Response) => {
      return response;
    }), catchError(e => {
      if (e.status === 422) {
        e = e;
        var err: any = {};
        if (e["errors"]) {
          err.message = e["errors"][Object.keys(e["errors"])[0]];
        } else {
          err.message = e.message;
        }
        return observableThrowError(err);
      }
      return observableThrowError(e);
    }));
  }

  //LOGOUT FUNCTION INVALIDATE TOKEN
  logout(): Observable<any> {
    return this.http.get(this.baseApiUrl + "logout", this.getLogOutHeaders()).pipe(map((response : Response) => {
      return response;
    }));
  }

  //FOR FORMDATA
  sendFormData(endpoint, obj): Observable<any> {
    return this.http.post(this.baseApiUrl + endpoint, obj, this.getFormdataHeaders()).pipe(map((response : Response) => {
      return response;
    }), catchError(e => {
      if (e.status === 422) {
        e = e;
        var err: any = {};
        if (e["errors"]) {
          err.message = e["errors"][Object.keys(e["errors"])[0]];
          console.log("err.message", err.message);
        } else {
          err.message = e.message;
        }
        return observableThrowError(err);
      }
      return observableThrowError(e);
    }));
  }

  videoUpload(endpoint, obj): Observable<any> {
    return this.http.post( endpoint, obj).pipe(map((response : Response) => {
      return response;
    }), catchError(e => {
      if (e.status === 422) {
        e = e;
        var err: any = {};
        if (e["errors"]) {
          err.message = e["errors"][Object.keys(e["errors"])[0]];
          console.log("err.message", err.message);
        } else {
          err.message = e.message;
        }
        return observableThrowError(err);
      }
      return observableThrowError(e);
    }));
  }

  getCountryCode(endpoint): Observable<any> {
    return this.http.get(this.baseApiUrl + endpoint, this.getFormdataHeaders()).pipe(map((response : Response) => {
      return response;
    }), catchError(e => {
      return this.catchErrors(e);
    }));
  }

  /* FOR AUTHENTICATED REQUESTS */
  getData(endpoint): Observable<any> {
    return this.http.get(this.baseApiUrl + endpoint, this.getCommonHeaders()).pipe(map((response : Response) => {
      return response;
    }), catchError(e => {
      return this.catchErrors(e);
    }));
  }
  /* FOR AUTHENTICATED REQUESTS */
  getDataCampaign(endpoint): Observable<any> {
    return this.http.get(this.baseApiUrlCampaign + endpoint, this.getCampaignHeaders()).pipe(map((response: Response) => {
      return response;
    }), catchError(e => {
      return this.catchErrors(e);
    }));
  }
  getDataAutomation(endpoint): Observable<any> {
    return this.http.get(this.baseApiUrlAutomation + endpoint, this.getAutomationsHeader()).pipe(map((response: Response) => {
      return response;
    }), catchError(e => {
      return this.catchErrors(e);
    }));
  }
  deleteDataAutomation(endpoint): Observable<any> {
    return this.http.delete(this.baseApiUrlAutomation + endpoint, this.getAutomationsHeader()).pipe(map((response: Response) => {
      return response;
    }), catchError(e => {
      return this.catchErrors(e);
    }));
  }

  sendDataCampaign(endpoint, data): Observable<any> {
    return this.http.post(this.baseApiUrlCampaign + endpoint, data, this.getCampaignHeaders()).pipe(map((response: Response) => {
      return response;
    }), catchError(e => {
      return this.catchErrors(e);
    }));
  }

  sendDataAutomation(endpoint, data): Observable<any> {
    return this.http.post(this.baseApiUrlAutomation + endpoint, data, this.getAutomationsHeader()).pipe(map((response: Response) => {
      return response;
    }), catchError(e => {
      return this.catchErrors(e);
    }));
  }

  updateDataCampaign(endpoint, data): Observable<any> {
    return this.http.put(this.baseApiUrlCampaign + endpoint, data, this.getCampaignHeaders()).pipe(map((response: Response) => {
      return response;
    }), catchError(e => {
      return this.catchErrors(e);
    }));
  }

  deleteDataCampaign(endpoint): Observable<any> {
    return this.http.delete(this.baseApiUrlCampaign + endpoint, this.getCampaignHeaders()).pipe(map((response: Response) => {
      return response;
    }), catchError(e => {
      return this.catchErrors(e);
    }));
  }

  updateDataAutomation(endpoint, data): Observable<any> {
    return this.http.put(this.baseApiUrlAutomation + endpoint, data, this.getAutomationsHeader()).pipe(map((response: Response) => {
      return response;
    }), catchError(e => {
      return this.catchErrors(e);
    }));
  }

  getMock(endpoint): Observable<any> {
      return this.http.get(endpoint).pipe(map((response : Response)=>{
          return response;
      }))
  }

  sendData(endpoint, data, baseUrl = null): Observable<any> {
    return this.http.post((baseUrl || this.baseApiUrl) + endpoint, data, this.getCommonHeaders()).pipe(map((response : Response) => {
      return response;
    }), catchError(e => {
      return this.catchErrors(e);
    }));
  }

  searchClient(endpoint): Observable<any> {
    return this.http.get(this.baseApiUrl + endpoint, this.searchClientHeader()).pipe(map((response : Response) => {
      return response;
    }), catchError(e => {
      return this.catchErrors(e);
    }));
  }

  // sendDataBlob(endpoint, data) {
  //   this.http.post(this.baseApiUrl + endpoint, data, this.getBlobHeader()).subscribe(success => {
  //     console.log("response", success);
  //   }, err => {
  //     console.log("err", err);
  //   });
  // }

  updateData(endpoint, data): Observable<any> {
    return this.http.put(this.baseApiUrl + endpoint, data, this.getCommonHeaders()).pipe(map((response : Response) => {
      return response;
    }), catchError(e => {
      return this.catchErrors(e);
    }));
  }

  patchData(endpoint, data): Observable<any> {
    return this.http.patch(this.baseApiUrl + endpoint, data, this.getCommonHeaders()).pipe(map((response : Response) => {
      return response;
    }), catchError(e => {
      return this.catchErrors(e);
    }));
  }

  deleteData(endpoint): Observable<any> {
    return this.http.delete(this.baseApiUrl + endpoint, this.getCommonHeaders()).pipe(map((response : Response) => {
      return response;
    }), catchError(e => {
      return this.catchErrors(e);
    }));
  }

  deleteBody(endpoint, data): Observable<any> {

    const options = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
            Accept: "application/x.gymday.v1+json",
            Authorization: "Bearer " + this.getToken()
        }),
        body: data,
      };

      return this.http
        .delete(this.baseApiUrl + endpoint, options)
        .pipe(map((response: Response)  => {
            if (!response) {
                return {message : 'success'}
            }
            return response;
          }),
          catchError(e => {
            return this.catchErrors(e);
          }));

  }

  deleteBodyCampaign(endpoint, data): Observable<any> {

    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getToken()
      }),
      body: data,
    };

    return this.http
      .delete(this.baseApiUrlCampaign + endpoint, options)
      .pipe(map((response: Response) => {
        if (!response) {
          return { message: 'success' }
        }
        return response;
      }),
        catchError(e => {
          return this.catchErrors(e);
        }));

  }


  deleteDataBody(endpoint, obj): Observable<any> {
    // return this.http.delete(this.baseApiUrl + endpoint, { headers: this.getCommonHeaders(), body: obj })
    return this.http.delete(this.baseApiUrl + endpoint, this.getCommonHeaders()).pipe(map((response : Response) => {
      return response;
    }), catchError(e => {
      return this.catchErrors(e);
    }));
  }

  catchErrors(e) {
    var result;
    let errorMessage;
    errorMessage = e?.error?.message;
    if (e.status == 401) {
      result = this.rejectRequest(e);
    }
    else if(e.status === 503){
      result = this.maintenanceError(e);
    }

    else if (e.status == 403) {
      let err = {
        message: e.error.message
      };
      result = observableThrowError(err)
      this.generalFunctions.openToast(err.message, 5000, 'error');
      return result;
    }

    else if (e.status === 404) {
      const err: any = {
        message: e.error.message
      };
      if (e.error?.contact_available_in_center !== undefined) {
        err.contact_available_in_center = e.error.contact_available_in_center;
      }
      if (e.error?.is_accessible !== undefined) {
        err.is_accessible = e.error.is_accessible;
      }
      result = observableThrowError(err);
    }

    else if (e.status == 500) {
      var err = {
        message: e?.error?.message ? e.error.message : "Something weird happened. We are looking into it and will fix it soon."
      };
      errorMessage =  err.message;
      result = observableThrowError(err);
    }

    else if (e.status == 422) {
      let err: any = {};
      if (e.error.errors) {
          err.message = e.error["errors"][Object.keys(e.error["errors"])[0]];
      }
      else {
          err.message = "Please enter valid data";
      }
      errorMessage =  err.message;
      result = observableThrowError(err);
    }

    else if(e.status === 411){
      let err = {
        message: e.error.message
      };
      result = observableThrowError(err)
      this.router.navigate(['/client/no-access']);
    }

    else if (e.status == 413) {
      result = observableThrowError(e);
    }

    else if (e.status == 400) {
      let err = {
        message: e.error.message,
        status_code: 400
      };
      result = observableThrowError(err);
    }

    else if (e.status == 409) {
      let err = {
        message: e.error.message
      };
      result = observableThrowError(err);
    }

    else if (e.status === 405) {
      let err = {
        message: e.error.message
      };
      result = observableThrowError(err);
    }

    else if (e.status === 504) {
      let err = {
        message: 'Request time out.'
      };
      errorMessage =  err.message;
      result = observableThrowError(err);
    }

    else if (e.status === 0) {
      result = observableThrowError({message: e.message});
    }

    else {
      result = observableThrowError(err);
    }

    posthog.capture(e.name,
    {
      "message": errorMessage ? errorMessage : 'Something went wrong, please try again after sometime',
      "url" : e?.url,
      "status" : e?.error?.status_code
    });
    return result;
  }

  rejectRequest(e) {
    localStorage.removeItem("currentUser");
    this.generalFunctions.clearCookies();
    localStorage.removeItem("currentCenter");
    this.router.navigate(["login"]);
    var err = {
      message: "Invalid user!"
    };
    return observableThrowError(err);
  }

  maintenanceError(e){
    localStorage.removeItem("currentUser");
    this.generalFunctions.clearCookies();
    localStorage.removeItem("currentCenter");
    this.router.navigate(["login"]);
    return observableThrowError(e);
  }

  getExternalData(externalEndpoint : string): Observable<any> {
    return this.http.get(externalEndpoint).pipe(map((response : Response) => {
      return response;
    }), catchError(e => {
      return e;
    }));
  }
}

@Injectable()
export class MyInterceptor implements HttpInterceptor {
  intercept(req : HttpRequest<any>, next : HttpHandler): Observable<HttpEvent<any>> {
    if(!window.navigator.onLine) {
      // if there is no internet, throw a HttpErrorResponse error
      // since an error is thrown, the function will terminate here
      let err = {
        status: 404,
        error: {
          message: "Not connected"
        }
      };
      return observableThrowError(err);
    } else {
      // else return the normal request
      return next.handle(req);
    }
  }
}

@Injectable()
export class WafInterceptor implements HttpInterceptor {

  constructor(private authService: GeneralServices) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const token = this.authService.getCookie('aws-waf-token')
    if (request.url.includes('upload.cloudflarestream') || request.url.includes('upload.videodelivery')) {
      return next.handle(request);
    }

    if (token) {
      const headers = request.headers.set('x-aws-waf-token', token);
      const modifiedRequest = request.clone({ headers });
      return next.handle(modifiedRequest);
    }

    return next.handle(request);
  }
}

@Injectable()
export class GiftCardService {
    sub: Subject<any>;


    constructor() {
        this.sub = new Subject();
    }

    giftCardValidity(data) {
         this.sub.next(data)

    }
}
